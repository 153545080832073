export type User = {
  displayName?: string;
  userPrincipalName?: string;
  onlineVoiceRoutingPolicy?: string;
  alias?: string;
  tenantDialPlan?: string;
  interpretedUserType?: string;
  onPremLineURI?: string;
  identity?: string;
  tenantId?: string;
  dirtyProps?: string[];
  assignedPlan?: string[];
  featureTypes?: string[];
  voiceLicenseStatus?: number;
  callerIdPolicy?: string;
  status?: number;
};

export type ManageFilter = {
  all: number | boolean;
  status: {
    unmodified: number | boolean;
    pending: number | boolean;
    success: number | boolean;
    errored: number | boolean;
  };
  identityType: { users: number | boolean; resource: number | boolean };
  number: { withNumber: number | boolean; withoutNumber: number | boolean };
  voiceRoute: { withVR: number | boolean; withoutVR: number | boolean };
  dialPlan: { withDP: number | boolean; withoutDP: number | boolean };
  license: { withLicense: number | boolean; withoutLicense: number | boolean };
};

export type TenantDialPlan = {
  identity: string;
  name: string;
  simpleName: string;
  description: string;
  status: number;
};

export type VoiceRoutePolicy = {
  identity: string;
  name: string;
  description: string;
  status: number;
};

export type TenantNumber = {
  name: string;
  id: string;
  status: number;
  isAssigned: boolean;
  number: string;
};

export type DownloadTenantStatusResponse = {
  status: number;
  blob?: Blob;
};

export type TenantTheme = {
  logoUrl?: string;
  loginImageUrl?: string;
  appName?: string;
  primaryColor?: string;
  secondaryColor?: string;
};

export enum TenantServicePrincipalSettingsState {
  None = 0,
  Optional = 1,
  Mandatory = 2,
}

export enum JobUserSyncStatus {
  Pending = 0,
  Failed = 1,
  Success = 2,
  InProgress = 3
}

export enum JobUserSyncSource {
  Api = 0,
  Worker = 1,
}

export type JobUserSync = {
  id: string;
  tenantId: string;
  status: JobUserSyncStatus;
  groupId: string;
  skip: number;
  limit: number;
  isSoftDeleted: boolean;
  retries?: number;
  dateQueued?: string;
  dateCreated?: string;
  dateCompleted?: string;
  source: JobUserSyncSource;
  errorMessage?: string;
};

export type JobUserSyncGrouped = {
  groupId: string;
  jobs: JobUserSync[];
  lastCompletedDate?: string;
  totalJobs: number;
};

export type TenantData = {
  serviceType?: string;
  status?: number;
  tenantId: string;
  theme?: TenantTheme;
  id: string;
  msTenantId: string;
  isServicePrincipalCredentialsSaved?: boolean;
  servicePrincipalState?: TenantServicePrincipalSettingsState;
  jobs: JobUserSyncGrouped[];
};

export type ManageIdentityFilter = {
  page: number;
  limit: number;
  searchKey?: string;
  identityFilter: number[];
  numberFilter: number[];
  voiceRouteFilter: number[];
  dialPlanFilter: number[];
  licenseFilter: number[];
};
