import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders, getHeadersObject, getTeamsToken } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { customFetch, customFetchBaseQuery } from './customFetch';
import { TenantDialPlan } from 'types';

export const dialPlanApi = createApi({
  reducerPath: 'dialPlanApi',
  tagTypes: ['DialPlan'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    GetDialPlans: builder.mutation({
      query: ({ token }) => ({
        url: 'powershell/getdialplans',
        headers: getHeaders(token),
        method: 'GET',
      }),
      invalidatesTags: [{ type: 'DialPlan', id: 'LIST' }],
    }),
  }),
});

export const { useGetDialPlansMutation } = dialPlanApi;

export const getDialPlans = async (): Promise<TenantDialPlan[]> => {
  const token = getTeamsToken();
  const res = await customFetch<TenantDialPlan[]>(`${baseUrl}powershell/getdialplans`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};
