/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

interface ContextInterface {
  Consumer: any;
  Provider: any;
}

const Context: ContextInterface = createContext({
  filters: {},
  setFilters: () => null,
  isCompleted: false,
  setIsCompleted: () => null,
  isSaving: false,
  setIsSaving: () => null,
  modifiedData: {},
  setModifiedData: () => null,
  searchKey: '',
  setSearchKey: () => null,
  currentPage: '',
  setCurrentPage: () => null,
  reload: () => null,
  dialPlans: [],
  getDialPlans: null,
  isDialPlanLoading: false,
  identities: [],
  getIdentities: null,
  isIdentitiesLoading: false,
  numbers: [],
  getNumbers: null,
  isNumbersLoading: false,
  voiceRoutePolicies: [],
  getVoiceRoutePolicies: null,
  isVoiceRouteLoading: false,
  isMobileSuccess: false,
  setIsMobileSuccess: () => null,
  resetCache: null,
  isResettingCache: false,
  isStateFreeze: false,
  setIsStateFreeze: () => null,
  tenant: null,
  isComparing: false,
  compareResults: null,
  compare: () => null,
});

export const { Consumer, Provider } = Context;

export default Context;
