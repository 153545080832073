/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import ProgressBar from 'components/common/ProgressBar';
import usePendingUserSyncJobs from 'hooks/useUserSyncJob';
import { createContext, PropsWithChildren, useContext } from 'react';
import { useAppContext } from './AppContext';

type UserSyncState = {
  completed: boolean;
  progress: number;
  loading: boolean;
  totalUsers: number;
  syncedUsers: number;
  startSync: () => void;
};

type UserSyncContextProps = {};

const UserSyncContext = createContext<UserSyncState>({
  completed: false,
  progress: 0,
  loading: false,
  totalUsers: 0,
  syncedUsers: 0,
  startSync: () => { },
});

const UserSyncProvider = ({ children }: PropsWithChildren<UserSyncContextProps>) => {
  const {
    loading,
    progress,
    completed,
    totalUsers,
    syncedUsers,
    startSync,
  } = usePendingUserSyncJobs();

  return (
    <UserSyncContext.Provider
      value={{
        completed,
        progress,
        loading,
        totalUsers,
        syncedUsers,
        startSync,
      }}
    >
      {children}
    </UserSyncContext.Provider>
  );
};

const useUserSyncContext = () => useContext(UserSyncContext);

export { UserSyncContext, UserSyncProvider, useUserSyncContext };
