import { Loader, Provider } from '@fluentui/react-northstar';
import { AppContextProvider, useAppContext } from 'contexts/AppContext';
import AuthContextProvider from 'contexts/AuthContext';
import { FeaturesContextProvider } from 'contexts/FeaturesContext';
import { useTeamsFx } from 'hooks/useTeamsFx';
import './App.css';
import Routes from './Routes';
import { UserSyncProvider } from 'contexts/UserSyncContext';

const ThemedApp = (): React.ReactElement => {
  const { isInitialized, appTheme } = useAppContext();
  const { loading, isInTeams } = useTeamsFx();
  if (loading || !isInitialized) return <Loader style={{ margin: 100 }} />;
  const styles = isInTeams ? { padding: '.5rem 1.5rem', height: '100%' } : undefined;
  return (
    <Provider theme={appTheme} style={styles}>
      <AuthContextProvider>
        <FeaturesContextProvider>
          <UserSyncProvider>
            <Routes />
          </UserSyncProvider>
        </FeaturesContextProvider>
      </AuthContextProvider>
    </Provider>
  );
};

const App = (): React.ReactElement => (
  <AppContextProvider>
    <ThemedApp />
  </AppContextProvider>
);

export default App;
