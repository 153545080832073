import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders, getHeadersObject, getTeamsToken } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { customFetch, customFetchBaseQuery } from './customFetch';
import { VoiceRoutePolicy } from 'types';

export const voicePolicyApi = createApi({
  reducerPath: 'voicePolicyApi',
  tagTypes: ['VoicePolicy'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getVoiceRoutePolicies: builder.mutation({
      query: ({ token }) => ({
        url: 'powershell/getvoiceroutepolicies',
        headers: getHeaders(token),
        method: 'GET',
      }),
      invalidatesTags: [{ type: 'VoicePolicy', id: 'LIST' }],
    }),
  }),
});

export const { useGetVoiceRoutePoliciesMutation } = voicePolicyApi;

export const getVoiceRoutePolicies = async (): Promise<VoiceRoutePolicy[]> => {
  const token = getTeamsToken();
  const res = await customFetch<VoiceRoutePolicy[]>(`${baseUrl}powershell/getvoiceroutepolicies`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};
