import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { startSyncUsers, getUserSyncJobsByIds, getPendingUserSyncJobs } from 'redux/services/userSyncJobApi';
import { RootState } from 'redux/store';
import { JobUserSyncStatus } from 'types/userJob';
import { useMutation } from './useMutation';

const RETRY_INTERVAL = 5000;

const usePendingUserSyncJobs = () => {
  const [enabled, setEnabled] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [syncedUsers, setSyncedUsers] = useState(0);
  const interval = useRef<number | undefined>();
  const [progress, setProgress] = useState(0);
  const jobs = useRef<string[]>([]);
  const token = useSelector((state: RootState) => state.auth.token);

  const { mutate, loading: isStartSyncLoading } = useMutation<void, string[]>({
    callback: async (): Promise<string[]> => {
      return startSyncUsers();
    },
    onSuccess: (res) => {
      setEnabled(true);
      interval.current = undefined;
      jobs.current = res ?? [];
    },
  });

  const fetchPendingUserSyncJobs = async () => {
    const result = await getPendingUserSyncJobs();
    if (result.length > 0) {
      setEnabled(result.length > 0);
      interval.current = undefined;
      jobs.current = result.map((job) => job.id);
    }
  };

  const fetchUserSyncJobs = async () => {
    const result = await getUserSyncJobsByIds(jobs.current);
    const totalJobsCount = jobs.current.length;
    const resJobs = result?.jobs ?? [];
    setSyncedUsers(result?.syncedUsers ?? 0);

    if (!totalUsers) {
      setTotalUsers(result?.totalUsers ?? 0);
    }

    const pendingJobsCount = resJobs?.filter((job) => job.status === JobUserSyncStatus.Pending).length ?? 0;
    const progressPercentage = ((totalJobsCount - pendingJobsCount) / totalJobsCount) * 100;
    setProgress(parseInt(progressPercentage.toString(), 10));
    if (pendingJobsCount === 0) {
      setEnabled(false);
      setCompleted(true);
      clearInterval(interval.current);
      interval.current = undefined;
      return;
    }
  };

  const startSync = async () => {
    // Do not process if there are pending jobs being processed
    if (enabled) return;
    mutate();
  };

  useEffect(() => {
    if (!!interval.current || !enabled) return;

    fetchUserSyncJobs();
    interval.current = window.setInterval(async () => {
      await fetchUserSyncJobs();
    }, RETRY_INTERVAL);
  }, [token, enabled]);

  useEffect(() => {
    fetchPendingUserSyncJobs();
  }, []);

  return {
    completed,
    progress,
    loading: isStartSyncLoading || enabled,
    totalUsers,
    syncedUsers,
    refetch: fetch,
    startSync,
  };
};

export default usePendingUserSyncJobs;
