import { useContext } from 'react';
import { Button, Checkbox, Divider, Flex, FlexItem, Header, Form, Popup } from '@fluentui/react-northstar';
import { ChevronDownIcon, FilterIcon } from '@fluentui/react-icons-northstar';
import Context from '../../context';
import { flattenObject } from '../../../../helpers/utils';
import {
  allCheckboxStyles,
  buttonStyles,
  checkboxStyles,
  dividerStyles,
  headerStyles,
  formStyles,
  wrapperStyles,
} from './FilterPopup.styles';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { useUserSyncContext } from 'contexts/UserSyncContext';
import { useAppContext } from 'contexts/AppContext';

const FilterPopup = (): React.ReactElement => {
  const { loading: syncLoading } = useUserSyncContext();
  const { appTheme } = useAppContext();
  const { filters, setFilters, setIsStateFreeze } = useContext(Context);
  const dimensions = useScreenSize();

  const {
    all,
    status: { unmodified, pending, success, errored },
    identityType: { users, resource },
    number: { withNumber, withoutNumber },
    voiceRoute: { withVR, withoutVR },
    dialPlan: { withDP, withoutDP },
    license: { withLicense, withoutLicense },
  } = filters;

  const handleSelectAll = () => {
    const currentFilter = { ...filters };
    const filterItems = ['status', 'identityType', 'number', 'voiceRoute', 'dialPlan', 'license'];
    currentFilter.all = !currentFilter.all;
    filterItems.forEach((item: string) => {
      currentFilter[item] = {
        ...Object.keys(currentFilter[item]).reduce(
          (reduced, key) => ({
            ...reduced,
            [key]: currentFilter.all,
          }),
          {},
        ),
      };
    });
    setFilters(currentFilter);
  };

  const updateFilter = (parent: string, child: string, value: boolean) => {
    const currentFilter = { ...filters };
    currentFilter[parent][child] = value;

    delete currentFilter.all;

    const isAllChecked = flattenObject(currentFilter);

    currentFilter.all = Object.values(isAllChecked).every(Boolean);

    if (!Object.values(currentFilter[parent]).some(Boolean)) {
      currentFilter[parent][child] = !value;
    }

    if (parent === 'status') {
      setIsStateFreeze(false);
    }

    setFilters(currentFilter);
  };

  return (
    <Popup
      unstable_pinned
      position="below"
      content={
        <Form
          style={{
            ...formStyles,
            width: 400,
            height: dimensions.height < 500 ? '60vh' : 'auto',
            overflow: dimensions.height < 500 ? 'scroll' : 'auto',
          }}
        >
          {syncLoading && (
            <div
              style={{
                flexGrow: 1,
                width: '100%',
                borderRadius: 4,
                border: `1px solid ${appTheme?.siteVariables?.colors?.grey?.[200]}`,
                backgroundColor: appTheme?.siteVariables?.white,
                padding: 10,
                marginBottom: 16,
              }}
            >
              <p style={{ margin: 0 }}>
                Users are currently being synced and this may take a while. Some users might
                not be available when filtering until the sync is complete.
              </p>
            </div>
          )}
          <Checkbox
            checked={all}
            label="All"
            style={allCheckboxStyles}
            onClick={handleSelectAll}
            data-testid="filter--all"
          />
          <Divider style={dividerStyles} />
          <Flex gap="gap.small">
            <div>
              <Header as="h5" content="Status" style={headerStyles} />
              <Checkbox
                checked={unmodified}
                label="Unmodified"
                style={checkboxStyles}
                onClick={() => updateFilter('status', 'unmodified', !unmodified)}
                data-testid="filter--unmodified"
              />
              <Checkbox
                checked={pending}
                label="Pending"
                style={checkboxStyles}
                onClick={() => updateFilter('status', 'pending', !pending)}
                data-testid="filter--pending"
              />
              <Checkbox
                checked={success}
                label="Success"
                style={checkboxStyles}
                onClick={() => updateFilter('status', 'success', !success)}
                data-testid="filter--success"
              />
              <Checkbox
                checked={errored}
                label="Errored"
                style={checkboxStyles}
                onClick={() => updateFilter('status', 'errored', !errored)}
                data-testid="filter--errored"
              />
              <Divider style={dividerStyles} />
              <Header as="h5" content="Indentity Type" style={headerStyles} />
              <Checkbox
                checked={users}
                label="Users"
                style={checkboxStyles}
                onClick={() => updateFilter('identityType', 'users', !users)}
                data-testid="filter--users"
              />
              <Checkbox
                checked={resource}
                label="Resource"
                style={checkboxStyles}
                onClick={() => updateFilter('identityType', 'resource', !resource)}
                data-testid="filter--resource"
              />
              <Divider style={dividerStyles} />
              <Header as="h5" content="Number" style={headerStyles} />
              <Checkbox
                checked={withNumber}
                label="With Number"
                style={checkboxStyles}
                onClick={() => updateFilter('number', 'withNumber', !withNumber)}
                data-testid="filter--with-number"
              />
              <Checkbox
                checked={withoutNumber}
                label="Without Number"
                style={checkboxStyles}
                onClick={() => updateFilter('number', 'withoutNumber', !withoutNumber)}
                data-testid="filter--without-number"
              />
            </div>
            <FlexItem>
              <div style={wrapperStyles}>
                <Header as="h5" content="Voice Route Policy" style={headerStyles} />
                <Checkbox
                  checked={withVR}
                  label="With Voice Route Policy"
                  style={checkboxStyles}
                  onClick={() => updateFilter('voiceRoute', 'withVR', !withVR)}
                  data-testid="filter--with-vrp"
                />
                <Checkbox
                  checked={withoutVR}
                  label="Without Voice Route Policy"
                  style={checkboxStyles}
                  onClick={() => updateFilter('voiceRoute', 'withoutVR', !withoutVR)}
                  data-testid="filter--without-vrp"
                />
                <Divider style={dividerStyles} />
                <Header as="h5" content="Dial Plan" style={headerStyles} />
                <Checkbox
                  checked={withDP}
                  label="With Dial Plan"
                  style={checkboxStyles}
                  onClick={() => updateFilter('dialPlan', 'withDP', !withDP)}
                  data-testid="filter--with-dial-plan"
                />
                <Checkbox
                  checked={withoutDP}
                  label="Without Dial Plan"
                  style={checkboxStyles}
                  onClick={() => updateFilter('dialPlan', 'withoutDP', !withoutDP)}
                  data-testid="filter--without-dial-plan"
                />
                <Divider style={dividerStyles} />
                <Header as="h5" content="Voice License" style={headerStyles} />
                <Checkbox
                  checked={withLicense}
                  label="With Voice License"
                  style={checkboxStyles}
                  onClick={() => updateFilter('license', 'withLicense', !withLicense)}
                  data-testid="filter--with-license"
                />
                <Checkbox
                  checked={withoutLicense}
                  label="Without Voice License"
                  style={checkboxStyles}
                  onClick={() => updateFilter('license', 'withoutLicense', !withoutLicense)}
                  data-testid="filter--without-license"
                />
              </div>
            </FlexItem>
          </Flex>
        </Form>
      }
      trapFocus
      trigger={
        <Button
          content={<FilterIcon />}
          icon={<ChevronDownIcon />}
          iconPosition="after"
          style={buttonStyles}
          data-testid="toolbar--search-filter"
        />
      }
    />
  );
};

export default FilterPopup;
