import { getHeadersObject, getTeamsToken } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { customFetch } from './customFetch';
import { TenantJobsResponse, UserJobSync } from 'types/userJob';

export const getPendingUserSyncJobs = async (): Promise<UserJobSync[]> => {
  const token = getTeamsToken();
  const res = await customFetch<UserJobSync[]>(`${baseUrl}job/GetPendingUserSyncJobs`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};

export const getUserSyncJobsByIds = async (ids: string[] = []): Promise<TenantJobsResponse> => {
  const token = getTeamsToken();
  const query = ids.map((id) => `jobIds=${id}`).join('&');
  const res = await customFetch<TenantJobsResponse>(`${baseUrl}job/GetUserSyncJobs?${query}`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};

export const startSyncUsers = async (): Promise<string[]> => {
  const token = getTeamsToken();
  const res = await customFetch<string[]>(`${baseUrl}job/SyncUsers`, {
    method: 'POST',
    headers: getHeadersObject(token),
  });
  return res;
};

export const startServicePrincipalSync = async (tenantId: string): Promise<boolean> => {
  if (!tenantId) throw new Error('Invalid tenant id');
  const token = getTeamsToken();
  if (!token) throw new Error('Invalid session token');
  const res = await customFetch<{ success: boolean }>(`${baseUrl}job/SyncUsersWithCredentials`, {
    method: 'POST',
    headers: getHeadersObject(token),
    retries: 0,
    body: JSON.stringify({ tenantId }),
  });
  return !!res?.success;
};

export const reprocessJob = async (jobId: string): Promise<string> => {
  try {
    const token = getTeamsToken();
    await customFetch<string>(`${baseUrl}job/ReprocessJobWithCredentials`, {
      method: 'POST',
      headers: getHeadersObject(token),
      body: JSON.stringify({ jobUserSyncId: jobId }),
      skipParse: true,
    });
    return jobId;
  } catch (error) {
    return jobId;
  }
};

export const refreshJobsAuth = async (): Promise<boolean> => {
  const token = getTeamsToken();
  const res = await customFetch<{ success: boolean }>(`${baseUrl}job/RefreshJobs`, {
    method: 'POST',
    headers: getHeadersObject(token),
  });
  return !!res?.success;
};
