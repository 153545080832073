import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders, getHeadersObject, getTeamsToken } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { customFetch, customFetchBaseQuery } from './customFetch';
import { ManageIdentityFilter, User } from 'types';

export const identityApi = createApi({
  reducerPath: 'identityApi',
  tagTypes: ['Identity'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getIndentities: builder.mutation({
      query: ({ token, body }) => {
        const headers = getHeaders(token);
        headers.append('Content-Type', 'application/json');
        return {
          url: 'powershell/getpaginatedgraphusers',
          method: 'POST',
          headers,
          body,
        };
      },
      invalidatesTags: () => [{ type: 'Identity', id: 'LIST' }],
    }),
  }),
});

export const { useGetIndentitiesMutation } = identityApi;

export const getIdentities = async (filter?: ManageIdentityFilter): Promise<User[]> => {
  const token = getTeamsToken();
  const res = await customFetch<User[]>(`${baseUrl}powershell/getpaginatedgraphusers`, {
    method: 'POST',
    headers: getHeadersObject(token),
    body: !filter ? undefined : JSON.stringify(filter),
  });
  return res;
};
