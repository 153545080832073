export enum JobUserSyncStatus {
  Pending = 0,
  Failed = 1,
  Success = 2,
}

export type UserJobSync = {
  id: string;
  tenantId: string;
  status: JobUserSyncStatus;
  skip?: number;
  limit?: number;
  dateCreated: string;
};

export type TenantJobsResponse = {
  jobs: UserJobSync[];
  totalUsers: number;
  syncedUsers: number;
};
