import { createApi } from '@reduxjs/toolkit/query/react';
import { getHeaders, getHeadersObject, getTeamsToken } from 'helpers/utils';
import { baseUrl } from 'authConfig';
import { customFetch, customFetchBaseQuery } from './customFetch';
import { TenantNumber } from 'types';

export const numberApi = createApi({
  reducerPath: 'numberApi',
  tagTypes: ['Number'],
  baseQuery: customFetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    GetNumbers: builder.mutation({
      query: ({ token }) => ({
        url: 'commondata/getnumbers',
        headers: getHeaders(token),
        method: 'GET',
      }),
      invalidatesTags: [{ type: 'Number', id: 'LIST' }],
    }),
  }),
});

export const { useGetNumbersMutation } = numberApi;

export const getNumbers = async (): Promise<TenantNumber[]> => {
  const token = getTeamsToken();
  const res = await customFetch<TenantNumber[]>(`${baseUrl}commondata/getnumbers`, {
    method: 'GET',
    headers: getHeadersObject(token),
  });
  return res;
};
